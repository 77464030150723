import io from "socket.io-client";
import * as sharedMsg from "../../interface";
import L from "leaflet";
import $ from "jquery";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-select";
import "bootstrap-select/dist/css/bootstrap-select.css";

let socket: SocketIOClient.Socket;

var header = document.getElementById("header")!;
var homeIcon = document.getElementById("home")!;
var preScreen = document.getElementById("screenPre")!;
var lobbyScreen = document.getElementById("screenLobby")!;
var mapScreen = document.getElementById("screenMap")!;
var createScreen = document.getElementById("screenCreate")!;
var joinScreen = document.getElementById("screenJoin")!;

/// HTML Elements
const roundCounterSpan = document.getElementById("roundCounter")!;
const mapOverlayDiv = document.getElementById("mapOverlay")!;
const startGameBtn = <HTMLInputElement>document.getElementById("startGameBtn")!;
const createMissingNameErrorSpan$ = $("#createMissingName");
const joinMissingNameErrorSpan$ = $("#joinMissingName");
const joinMissingRoomErrorSpan$ = $("#joinMissingRoom");
const joinRoomNotFoundErrorSpan$ = $("#roomNotFound");
const userName1Input = <HTMLInputElement>document.getElementById("userName1");
const roomNameInput = <HTMLInputElement>document.getElementById("roomName");
// Settings
const savedSpan$ = $("#settingsSaved");
const gameRoundsInput = <HTMLInputElement>document.getElementById("gameRound");
const timeForPickInput = <HTMLInputElement>(
  document.getElementById("timeForPick")
);
const targetRadiusInput = <HTMLInputElement>(
  document.getElementById("targetRadius")
);
const hideCountryBtn = <HTMLInputElement>document.getElementById("hideCountry");
const populationMinBtn = <HTMLInputElement>(
  document.getElementById("enablePopulationMinimum")
);
const populationMinInput = <HTMLInputElement>(
  document.getElementById("populationMinimum")
);
const onlyCapitalsBtn = <HTMLInputElement>(
  document.getElementById("enableOnlyCapitals")
);
const countryList$ = $("#countryList");
const countryListBtn = <HTMLInputElement>(
  document.getElementById("enableCountryList")
);

var progessTimer: number;
var timeForPickLeft: number = 0;
var markers: L.Layer[] = [];
var playerColor: string = "";
var playerMarker: L.Marker;
var playerList: sharedMsg.Player[] = [];
var gameState: "pick" | "view" = "pick";

var mymap = L.map("mapid", {
  zoomSnap: 0.2,
  maxZoom: 7,
  // dragging: !L.Browser.mobile,
  // tap: !L.Browser.mobile,
});

var layer = L.tileLayer("map/{z}/{x}/{y}.png", {
  minZoom: 0,
  maxZoom: 7,
  subdomains: ["a.", "b.", "c.", "d."],
  noWrap: true,
});

if (
  window.location.hostname.includes("localhost") ||
  window.location.hostname.includes("10.48.0.10")
) {
  console.log("Running on localhost");
  socket = io(":1235");
} else {
  socket = io("");
}
socket.on("connect", () => {
  console.log("Connected to io");
  registerHandlers();
  const rejoinMsg: sharedMsg.RejoinMsg = {
    id: getId(),
  };
  socket.emit("rejoin", rejoinMsg, (room: string, name: string) => {
    console.log("Rejoining", room, name);
    joinGame(name, room);
  });
});

socket.on("disconnect", () => {
  console.log("Disconnect from server");
  alert("Connection to server lost.");
  resetHash();
  location.reload();
});

document.getElementById("home")!.addEventListener("click", () => {
  leaveRoom();
  enableScreen("pre");
});
document.getElementById("goToCreateBtn")!.addEventListener("click", () => {
  enableScreen("create");
});
document.getElementById("goToJoinBtn")!.addEventListener("click", () => {
  enableScreen("join");
});
document.getElementById("createGameBtn")!.addEventListener("click", createGame);
document
  .getElementById("joinGameBtn")!
  .addEventListener("click", joinGameClick);
document
  .getElementById("changeGameOptionsBtn")!
  .addEventListener("click", setGameOptions);
startGameBtn.addEventListener("click", startGame);

setupFormChangeListeners();
setupKeyListenersStartPage();
readHash();
resetMapView();
L.control.scale().addTo(mymap);
mymap.addLayer(layer);
mymap.on("click", onMapClick);

function createGame() {
  console.log("Creating game");
  const newGameMsg: sharedMsg.NewGameMsg = {
    palyerId: getId(),
    playerName: userName1Input.value,
    roomPassword: (<HTMLInputElement>document.getElementById("password1"))
      .value,
  };

  if (newGameMsg.playerName == "") {
    createMissingNameErrorSpan$.show();
    setTimeout(() => {
      createMissingNameErrorSpan$.fadeOut();
    }, 2000);
    return;
  }
  const ackFn: sharedMsg.NewGameMsgAckFn = (error, data) => {
    if (error) {
      alert(`Error creating room. Reason unknown. Please reload.`);
      return;
    }

    enableScreen("lobby");
    document.getElementById("l1RoomName")!.innerText = data.roomName;
    location.hash = "r=" + data.roomName;
  };

  console.log("PLAYER_ID:", getId());
  socket.emit("newRoom", newGameMsg, ackFn);
}

function leaveRoom() {
  socket.emit("leaveRoom");
}

function joinGameClick() {
  console.log("Joining game");

  const roomName = roomNameInput.value.toUpperCase();
  const playerName = (<HTMLInputElement>document.getElementById("userName2"))
    .value;

  if (roomName == "") {
    joinMissingRoomErrorSpan$.show();
    setTimeout(() => {
      joinMissingRoomErrorSpan$.fadeOut();
    }, 2000);
    return;
  }

  if (playerName == "") {
    joinMissingNameErrorSpan$.show();
    setTimeout(() => {
      joinMissingNameErrorSpan$.fadeOut();
    }, 2000);
    return;
  }

  joinGame(playerName, roomName);
}

function joinGame(playerName: string, roomName: string) {
  const joinGameMsg: sharedMsg.JoinGameMsg = {
    palyerId: getId(),
    playerName: playerName,
    roomName: roomName,
    roomPassword: (<HTMLInputElement>document.getElementById("password2"))
      .value,
  };

  socket.emit("joinRoom", joinGameMsg, (error: string | undefined) =>
    handleJoinGameResponse(error, joinGameMsg.roomName)
  );
}

function handleJoinGameResponse(error: string | undefined, roomName: string) {
  if (error) {
    joinRoomNotFoundErrorSpan$.show();
    setTimeout(() => {
      joinRoomNotFoundErrorSpan$.fadeOut();
    }, 2000);
    return;
  }
  enableScreen("lobby");
  document.getElementById("l1RoomName")!.innerText = roomName;
}

function setGameOptions() {
  console.log("Changing game options");
  if (playerIsHost() == false) {
    alert("Only host can change game options!");
    return;
  }
  const changeGameOptionsMsg: sharedMsg.ChangeGameOptionsMsg = {
    rounds: parseInt(gameRoundsInput.value),
    timeForPick: parseInt(timeForPickInput.value),
    targetRadius: parseInt(targetRadiusInput.value),
    citiesFilter: {},
    hideCountries: hideCountryBtn.checked,
  };

  if (onlyCapitalsBtn.checked) {
    changeGameOptionsMsg.citiesFilter.onlyCapitalCities = true;
  }

  if (countryListBtn.checked == true) {
    changeGameOptionsMsg.citiesFilter.cityInCountries = <string[]>(
      countryList$.val()
    );
  }

  if (populationMinBtn.checked == true) {
    changeGameOptionsMsg.citiesFilter.populationMinimum = parseInt(
      populationMinInput.value
    );
  }

  socket.emit("changeGameOptions", changeGameOptionsMsg, (success: boolean) => {
    if (success) {
      // TODO show error/success
      // disableChangeOptionsBtn();
      savedSpan$.stop(true, true);
      savedSpan$.show();
      setTimeout(() => {
        savedSpan$.fadeOut(1000);
      }, 500);
    }
    console.log("Game settings updated: " + success);
  });
}

function startGame() {
  if (
    (<HTMLButtonElement>document.getElementById("changeGameOptionsBtn"))
      .disabled == false
  ) {
    if (confirm("Unsaved Game Options! Start game anyways?") == false) {
      return;
    }
  }
  socket.emit("startGame");
}

function registerHandlers() {
  socket.on("prepareGame", (data: sharedMsg.prepareGameMsg) => {
    console.log("prepare");
    mapOverlayDiv.hidden = false;
    enableScreen("map");
    setTimeout(() => {
      mapOverlayDiv.hidden = true;
    }, data.delayMs);
  });
  socket.on("newRound", (data: sharedMsg.NewRoundMsg) => {
    onNewRound(data);
  });

  socket.on("roundOver", (data: sharedMsg.RoundOverMsg) => {
    onRoundOver(data);
  });

  socket.on("gameOver", (data: any) => {
    console.log("gameOver", data);
    removeMarkers();
    resetMapView();
    enableScreen("lobby");
  });

  socket.on("roomUpdate", (data: sharedMsg.RoomUpdateMsg) => {
    console.log("roomUpdate", data);
    playerList = data.players;
    playerColor = getPlayerById(getId())?.color ?? "";
    renderLoobyPlayerList(data.players);
    updateGameOptionsForm(data.gameOptions);
    renderIngamePlayerList(data.players);
  });
}

function onNewRound(data: sharedMsg.NewRoundMsg) {
  console.log("newRound", data);
  enableScreen("map");
  gameState = "pick";
  resetMapView();
  removeMarkers();
  markers = [];
  document.getElementById("nextCity")!.innerText = `${data.city} ${
    data.country != "" ? "(" + data.country + ")" : ""
  }`;
  roundCounterSpan.innerText = `${data.currentRound}/${data.rounds}`;

  timeForPickLeft = data.timeToPick * 1000;
  document.getElementById("timeBar")!.style.cssText = `width: 100%;`;
  const TICK_INTERVAL = 500;
  progessTimer = setInterval(() => {
    timeForPickLeft -= TICK_INTERVAL;
    const progressLeft = (timeForPickLeft / (data.timeToPick * 1000)) * 100;
    document.getElementById(
      "timeBar"
    )!.style.cssText = `width: ${progressLeft}%;`;
    if (timeForPickLeft <= 0) {
      clearInterval(progessTimer);
    }
  }, TICK_INTERVAL);
}

function removeMarkers() {
  markers.forEach((marker) => {
    mymap.removeLayer(marker);
  });
}

function onMapClick(event: L.LeafletMouseEvent) {
  if (gameState != "pick") {
    return;
  }
  const divIcon = L.divIcon({
    className: "playerMarker",
    iconSize: [26, 26],
    iconAnchor: [10, 26],
    html: `<i style="color: ${playerColor}" class="fas fa-map-marker-alt"></i>`,
  });
  if (playerMarker != null) {
    mymap.removeLayer(playerMarker);
    markers.splice(markers.indexOf(playerMarker), 1);
  }
  playerMarker = L.marker([0, 0], { icon: divIcon });
  playerMarker.setLatLng(event.latlng).addTo(mymap);
  markers.push(playerMarker);
  const pickLocationMsg: sharedMsg.PickLocationMsg = {
    latitude: event.latlng.lat,
    longitude: event.latlng.lng,
  };
  socket.emit("pickLocation", pickLocationMsg);
}

function onRoundOver(data: sharedMsg.RoundOverMsg) {
  console.log("roundOver", data);
  gameState = "view";
  const cityGeoPos: [number, number] = [
    data.realLocation.latitude,
    data.realLocation.longitude,
  ];
  // show city
  const divIcon = L.divIcon({
    className: "cityMarker",
    iconSize: [30, 30],
    iconAnchor: [4, 30],
    html: `<i class="fas fa-flag-checkered"></i>`,
  });
  const cityMarker = L.marker(cityGeoPos, { icon: divIcon });
  cityMarker.addTo(mymap);
  markers.push(cityMarker);
  // show points circle
  const pointsCircle = L.circle(cityGeoPos, {
    radius: parseInt(targetRadiusInput.value) * 1000,
    color: "red",
    opacity: 0.3,
    fill: false,
  });
  pointsCircle.addTo(mymap);
  markers.push(pointsCircle);
  // show others
  data.players.forEach((player) => {
    if (player.lastPick == null) {
      return;
    }
    const divIcon = L.divIcon({
      className: "playerMarker",
      iconSize: [26, 26],
      iconAnchor: [10, 26],
      html: `<i style="color: ${
        player.color
      }" class="fas fa-map-marker-alt"></i><span class="points">+${player.lastPickPoints?.toFixed(
        2
      )} Pts.</span>`,
    });
    const playerMarker = L.marker(
      [player.lastPick.latitude, player.lastPick.longitude],
      { icon: divIcon }
    );
    playerMarker.addTo(mymap);
    markers.push(playerMarker);

    // line
    const line = L.polyline(
      [
        [player.lastPick.latitude, player.lastPick.longitude],
        [data.realLocation.latitude, data.realLocation.longitude],
      ],
      { color: player.color }
    );
    line.addTo(mymap);
    markers.push(line);

    const pointA = mymap.latLngToContainerPoint([
      player.lastPick.latitude,
      player.lastPick.longitude,
    ]);
    const pointB = mymap.latLngToContainerPoint([
      data.realLocation.latitude,
      data.realLocation.longitude,
    ]);
    let angle =
      (Math.atan2(pointB.y - pointA.y, pointB.x - pointA.x) * 180) / Math.PI +
      90;
    angle += angle < 0 ? 360 : 0;

    if (angle > 180) {
      angle -= 180;
    }

    const distanceIcon = L.divIcon({
      className: "distanceMarkerContainer",
      html: `<div class="distanceMarker" style="transform: rotate(${
        angle - 90
      }deg) translate(0, 1rem); color: ${
        player.color
      }">${player.lastPickDistance?.toFixed(2)} km</div>`,
    });
    const distanceMarker = L.marker(
      [
        (player.lastPick.latitude + data.realLocation.latitude) / 2,
        (player.lastPick.longitude + data.realLocation.longitude) / 2,
      ],
      {
        icon: distanceIcon,
      }
    );
    distanceMarker.addTo(mymap);
    markers.push(distanceMarker);
  });
  zoomToFit(markers);

  renderIngamePlayerList(data.players);
}

function getId() {
  let id = sessionStorage.getItem("playerId");
  if (id == null) {
    id = Math.random().toString(20).substr(2, 10);
    sessionStorage.setItem("playerId", id);
  }
  return id;
}

function getPlayerById(id: string) {
  return playerList.find((player) => player.id === id);
}

function enableScreen(screen: "pre" | "lobby" | "create" | "join" | "map") {
  switch (screen) {
    case "lobby":
      headerNormal();
      homeIcon.hidden = false;
      lobbyScreen.hidden = false;
      preScreen.hidden = true;
      mapScreen.hidden = true;
      joinScreen.hidden = true;
      createScreen.hidden = true;
      break;
    case "map":
      headerMap();
      homeIcon.hidden = true;
      lobbyScreen.hidden = true;
      preScreen.hidden = true;
      mapScreen.hidden = false;
      joinScreen.hidden = true;
      createScreen.hidden = true;
      mymap.invalidateSize();
      document.getElementById("nextCity")!.scrollIntoView();
      break;
    case "pre":
      headerNormal();
      homeIcon.hidden = true;
      lobbyScreen.hidden = true;
      preScreen.hidden = false;
      mapScreen.hidden = true;
      joinScreen.hidden = true;
      createScreen.hidden = true;
      resetHash();
      break;
    case "create":
      headerNormal();
      homeIcon.hidden = false;
      lobbyScreen.hidden = true;
      preScreen.hidden = true;
      mapScreen.hidden = true;
      joinScreen.hidden = true;
      createScreen.hidden = false;
      userName1Input.focus();
      break;
    case "join":
      headerNormal();
      homeIcon.hidden = false;
      lobbyScreen.hidden = true;
      preScreen.hidden = true;
      mapScreen.hidden = true;
      joinScreen.hidden = false;
      createScreen.hidden = true;
      roomNameInput.focus();
      break;
  }
}

function headerNormal() {
  header.classList.add("py-5");
  header.classList.remove("py-lg-5");
}

function headerMap() {
  header.classList.add("py-lg-5");
  header.classList.remove("py-5");
}

function zoomToFit(layers: L.Layer[]) {
  let minLat = Infinity,
    maxLat = -Infinity,
    minLong = Infinity,
    maxLong = -Infinity;
  layers.forEach((marker) => {
    if (marker instanceof L.Marker) {
      minLat = Math.min(minLat, marker.getLatLng().lat);
      maxLat = Math.max(maxLat, marker.getLatLng().lat);
      minLong = Math.min(minLong, marker.getLatLng().lng);
      maxLong = Math.max(maxLong, marker.getLatLng().lng);
    }
  });

  disableMap();
  setTimeout(() => {
    mymap.fitBounds(
      [
        [minLat, minLong],
        [maxLat, maxLong],
      ],
      { paddingTopLeft: [30, 60], paddingBottomRight: [30, 30], maxZoom: 10 }
    );
    setTimeout(enableMap, 1000);
  }, 500);
}

function resetMapView() {
  mymap.setView([20.0, 0.0], 2);
}

function setupFormChangeListeners() {
  // Autosave
  gameRoundsInput.addEventListener("change", setGameOptions);
  timeForPickInput.addEventListener("change", setGameOptions);
  targetRadiusInput.addEventListener("change", setGameOptions);
  hideCountryBtn.addEventListener("change", setGameOptions);
  populationMinInput.addEventListener("change", setGameOptions);
  onlyCapitalsBtn.addEventListener("click", setGameOptions);
  countryList$.on("changed.bs.select", (e, clickedIndex) => {
    if (clickedIndex != null) {
      setGameOptions();
    }
  });

  // Enabled/Disable filters
  countryListBtn.addEventListener("click", (e) => {
    countryList$.prop("disabled", !countryListBtn.checked);
    countryList$.selectpicker("refresh");
    setGameOptions();
  });

  populationMinBtn.addEventListener("click", () => {
    populationMinInput.disabled = populationMinBtn.checked;
    setGameOptions();
  });
}

function setupKeyListenersStartPage() {
  userName1Input.addEventListener("keyup", (event: KeyboardEvent) => {
    if (event.key == "Enter") {
      createGame();
    }
  });

  (<HTMLInputElement>document.getElementById("userName2")).addEventListener(
    "keyup",
    (event: KeyboardEvent) => {
      if (event.key == "Enter") {
        joinGameClick();
      }
    }
  );

  roomNameInput.addEventListener("keyup", (event: KeyboardEvent) => {
    if (event.key == "Enter") {
      (<HTMLInputElement>document.getElementById("userName2")).focus();
    }
  });
}

function enableChangeOptionsBtn() {
  (<HTMLButtonElement>(
    document.getElementById("changeGameOptionsBtn")
  )).disabled = false;
}

function disableChangeOptionsBtn() {
  (<HTMLButtonElement>(
    document.getElementById("changeGameOptionsBtn")
  )).disabled = true;
}

function disableMap() {
  mymap.dragging.disable();
  mymap.touchZoom.disable();
  mymap.doubleClickZoom.disable();
  mymap.scrollWheelZoom.disable();
  mymap.boxZoom.disable();
  mymap.keyboard.disable();
  if (mymap.tap) mymap.tap.disable();
  document.getElementById("mapid")!.style.cursor = "default";
}

function enableMap() {
  mymap.dragging.enable();
  mymap.touchZoom.enable();
  mymap.doubleClickZoom.enable();
  mymap.scrollWheelZoom.enable();
  mymap.boxZoom.enable();
  mymap.keyboard.enable();
  if (mymap.tap) mymap.tap.enable();
  document.getElementById("mapid")!.style.cursor = "grab";
}

function updateGameOptionsForm(options: sharedMsg.GameOptions) {
  if (playerIsHost()) {
    enableSettings();
  }

  gameRoundsInput.value = options.rounds.toString();
  timeForPickInput.value = options.timeForPick.toString();
  targetRadiusInput.value = options.targetRadius.toString();
  hideCountryBtn.checked = options.hideCountries;

  if (options.citiesFilter.onlyCapitalCities == true) {
    onlyCapitalsBtn.checked = options.citiesFilter.onlyCapitalCities;
  } else {
    onlyCapitalsBtn.checked = false;
  }

  if (options.citiesFilter.cityInCountries != null) {
    countryList$.val(options.citiesFilter.cityInCountries); // requires refresh
    countryListBtn.checked = true;
    countryList$.prop("disabled", false);
  } else {
    countryList$.val([]); // requires refresh
    countryListBtn.checked = false;
    countryList$.prop("disabled", true);
  }

  if (options.citiesFilter.populationMinimum != null) {
    populationMinInput.value =
      options.citiesFilter.populationMinimum.toString();
    populationMinBtn.checked = true;
    populationMinInput.disabled = false;
  } else {
    populationMinBtn.checked = false;
    populationMinInput.disabled = true;
  }

  if (playerIsHost() == false) {
    disableSettings();
  }

  // needs to be refreshed when disabled/enabled
  countryList$.selectpicker("refresh");
}

function readHash() {
  const rPos = location.hash.indexOf("r=");
  if (rPos > 0) {
    roomNameInput.value = location.hash.substr(rPos + 2);
    enableScreen("join");
  }
}

function resetHash() {
  location.hash = "";
}

function renderLoobyPlayerList(players: sharedMsg.Player[]) {
  let playerListHtmlParts: string[] = [];

  // sort by score
  players.sort(sortPlayersByScore);

  players.forEach((player) => {
    playerListHtmlParts.push(
      `<tr class="row" ${player.id == getId() ? 'id="selfInList"' : ""}>
      <td class="col-6">
      <i class="fas fa-user" style="color: ${player.color}"></i>  
        ${sanitize(player.name)} ${player.isHost ? "(Host)" : ""}
      </td>
      <td class="col-6">
        Last Score: ${player.score?.toFixed(2) || "--"}
      </td>
      </tr>`
    );
  });
  const playerListHtml = playerListHtmlParts.join("");
  document.getElementById("l1playerList1")!.innerHTML = playerListHtml;
  document.getElementById("selfInList")?.addEventListener("click", () => {
    socket.emit("requestNewColor");
  });
}

function sortPlayersByScore(a: sharedMsg.Player, b: sharedMsg.Player) {
  if (a.score != null && b.score != null) {
    return a.score > b.score ? -1 : 1;
  }
  return 0;
}

function sortPlayersByDistance(a: sharedMsg.Player, b: sharedMsg.Player) {
  if (a.lastPickDistance != null && b.lastPickDistance != null) {
    return a.lastPickDistance < b.lastPickDistance ? -1 : 1;
  }
  return 0;
}

function renderIngamePlayerList(players: sharedMsg.Player[]) {
  let playerListHtmlParts: string[] = [];

  // sort by score
  players.sort(sortPlayersByDistance);

  players.forEach((player) => {
    const distInKm =
      player.lastPickDistance != null
        ? player.lastPickDistance.toFixed(2)
        : "--";
    playerListHtmlParts.push(
      `<li>
      <i class="fas fa-user" style="color: ${player.color}"></i>
      ${sanitize(player.name)}
      ${distInKm} km
      Pts: ${player.score?.toFixed(2) || "--"}
      </li>`
    );
  });
  const playerListHtml = playerListHtmlParts.join("");
  document.getElementById("ingameScoreList")!.innerHTML = playerListHtml;
}

function playerIsHost() {
  return getPlayerById(getId())?.isHost ?? false;
}

function disableSettings() {
  gameRoundsInput.disabled = true;
  timeForPickInput.disabled = true;
  targetRadiusInput.disabled = true;
  hideCountryBtn.disabled = true;
  populationMinBtn.disabled = true;
  populationMinInput.disabled = true;
  onlyCapitalsBtn.disabled = true;
  countryListBtn.disabled = true;
  countryList$.prop("disabled", true);

  startGameBtn.disabled = true;
}

function enableSettings() {
  gameRoundsInput.disabled = false;
  timeForPickInput.disabled = false;
  targetRadiusInput.disabled = false;
  hideCountryBtn.disabled = false;
  populationMinBtn.disabled = false;
  populationMinInput.disabled = false;
  onlyCapitalsBtn.disabled = false;
  countryListBtn.disabled = false;
  countryList$.prop("disabled", false);

  startGameBtn.disabled = false;
}

function sanitize(string: string) {
  const map: any = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;",
  };
  const reg = /[&<>"'/]/gi;
  return string.replace(reg, (match) => map[match]);
}
